import React from "react";
import "./whatsapp.css";

export default function Whatsapp() {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
      />

      <div className="fabs-container">
        <div className="fabs fabs-icon-holder">
          <i className="bi bi-whatsapp" style={{ fontSize: "1.5rem" }}></i>
        </div>

        <ul className="fabs-options">
          <a
            href="https://api.whatsapp.com/send/?phone=6281317284592&text=Halo%20Pak%20Alex,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..."
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <li>
              <span className="fabs-label">Customer Service Alex</span>
              <div className="fabs-icon-holder">
                <i className="bi bi-whatsapp"></i>
              </div>
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
}
