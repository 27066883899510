import { HomeImg, ProjectImg } from "../../assets/images";

export const highlight = [
  ProjectImg.projectImg1,
  ProjectImg.projectImg2,
  ProjectImg.projectImg3,
  ProjectImg.projectImg4,
];

export const Services = [
  {
    image: HomeImg.homeVector1,
    title: "Web Development",
    desc: "Rancang identitas digital yang unik dan kuat untuk bisnis Anda melalui layanan Web Development kami yang menawarkan solusi kreatif, fungsional, dan sesuai kebutuhan Anda.",
    navigate: "/services/web-development",
  },
  {
    image: HomeImg.homeVector2,
    title: "Mobile App Development",
    desc: "Wujudkan visi digital Anda melalui layanan Web & Mobile App Development kami yang menyediakan solusi terdepan, menciptakan pengalaman luar biasa bagi pengguna dari berbagai platform.",
    navigate: "/services/mobile-development",
  },
  {
    image: HomeImg.homeVector3,
    title: "Google Ads Management",
    desc: "Optimalkan potensi pemasaran online Anda melalui layanan Google Ads Management kami yang terukur, strategis, dan berkinerja tinggi untuk meraih hasil yang maksimal.",
    navigate: "/services/social-media-management",
  },
  {
    image: HomeImg.homeVector4,
    title: "Meta Ads Management",
    desc: "Optimalkan pengeluaran iklan Anda dan raih hasil yang maksimal, manfaatkan potensi penuh platform-platform Meta untuk pertumbuhan bisnis yang berkelanjutan.",
    navigate: "/services/google-ads-management",
  },
  {
    image: HomeImg.homeVector5,
    title: "Social Media Management",
    desc: "Optimalkan potensi pemasaran digital Anda melalui layanan Social Media Management kami yang menawarkan strategi kreatif, konten menarik untuk  platform media sosial Anda.",
    navigate: "/services/meta-ads-management",
  },
  {
    image: HomeImg.homeVector6,
    title: "Digital Marketing",
    desc: "Digital Marketing hadir untuk mendukung pertumbuhan bisnis Anda, memberikan analisis yang cermat, serta strategi yang terukur untuk memaksimalkan potensi pasar digital bagi bisnis Anda.",
    navigate: "/",
  },
];
